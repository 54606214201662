@import 'variables';

@import url('https://fonts.googleapis.com/css?family=Alegreya+Sans:400,900&display=swap&subset=cyrillic');
@import url('https://fonts.googleapis.com/css?family=Alegreya:400,400i&display=swap&subset=cyrillic');

@font-face {
	font-family: 'Alegreya Sans SC';
	src: url('/fonts/AlegreyaSansSC-Regular.eot');
	src: local('Alegreya Sans SC'),
		url('/fonts/AlegreyaSansSC-Regular.eot?#iefix') format('embedded-opentype'),
		url('/fonts/AlegreyaSansSC-Regular.woff2') format('woff2'),
		url('/fonts/AlegreyaSansSC-Regular.woff') format('woff'),
		url('/fonts/AlegreyaSansSC-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'Alegreya Sans SC';
	src: url('/fonts/AlegreyaSansSC-Bold.eot');
	src: local('Alegreya Sans SC'),
		url('/fonts/AlegreyaSansSC-Bold.eot?#iefix') format('embedded-opentype'),
		url('/fonts/AlegreyaSansSC-Bold.woff2') format('woff2'),
		url('/fonts/AlegreyaSansSC-Bold.woff') format('woff');
	font-weight: bold;
	font-display: swap;
}

.small {
	font-size: 12px;
	line-height: 16px;
}

.italic {
	font-style: italic;
}

.blue {
	color: $main-blue;
}

h1,
h2,
h3,
h4 {
	font-family: $header-font;
	font-style: normal;
	font-weight: bold;
}

h1 {
	font-size: 104px;
}

h2 {
	font-size: 72px;
	line-height: 72px;

	&.extra {
		font-weight: 900;
		letter-spacing: -0.01em;
	}
}

h3 {
	font-size: 56px;
}

h4 {
	font-size: 32px;
}

h5 {
	font-weight: 500;
	line-height: 32px;
	font-size: 32px;
	letter-spacing: 0.12em;
	color: $main-blue;
	font-family: $extra-font;
}

a {
	color: $main-blue;

	&:hover {
		color: $strong-blue;
	}
}

p {
	line-height: 24px;

	&.extra {
		font-size: 24px;
		line-height: 36px;

		@media (max-width: $screen-sm) {
			font-size: 16px;
			line-height: 24px;
		}
	}
}

span {
	color: $main-gray;
}

q {
	font-family: $common-font;
	font-style: italic;
}
