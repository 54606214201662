@import '../../../styles/variables.scss';

$main-black: #091a32;
$main-white: white;

$strong-blue: #08054c;
$main-blue: #4552af;
// $light-blue: white;

$strong-orange: #ff5500;
$main-orange: #ff8f2c;
$light-orange: #ffe0c4;

$strong-green: #00d4b6;
$main-green: #54e5ce;
$light-green: #bbf7f2;

$main-red: #ff5500;

.workLikeProLanding {
	position: relative;
	overflow-x: hidden;
	min-height: 100vh;

	.error {
		color: $main-red;
	}

	:global(.container-fluid) {
		padding: 0;
	}

	h2,
	h4,
	p,
	q {
		color: $strong-blue;

		span {
			color: inherit;
		}
	}

	h2 {
		line-height: 60px;
		letter-spacing: -0.01em;

		@media (max-width: $screen-sm) {
			font-size: 46px;
			line-height: 46px;
		}

		@media (max-width: $screen-sm) {
			font-size: 46px;
			line-height: 46px;
		}

		@media (max-width: 400px) {
			font-size: 37px;
			line-height: 37px;
		}
	}

	h4 {
		font-size: 40px;
		letter-spacing: 0.08em;
		font-family: $extra-font;
		margin-bottom: 8px;

		@media (max-width: $screen-sm) {
			font-size: 32px;
			line-height: 40px;
		}
	}

	h5 {
		line-height: 24px;
		font-size: 24px;
		color: $main-blue;
		font-weight: bold;
		letter-spacing: 0.08em;

		@media (max-width: $screen-sm) {
			line-height: 20px;
			font-size: 20px;
		}
	}

	q {
		display: inline-block;
		margin-bottom: 24px;
		line-height: 52px;
		font-size: 48px;
		quotes: none;

		@media (max-width: $screen-sm) {
			font-size: 32px;
			line-height: 40px;
			margin-bottom: 16px;
		}
	}

	p {
		font-size: 24px;
		line-height: 36px;

		@media (max-width: $screen-sm) {
			font-size: 16px;
			line-height: 24px;
		}
	}

	.mainHeaderTitle {
		margin-bottom: 16px;
	}

	.sectionIndent {
		margin-bottom: 96px;

		@media (max-width: $screen-md) {
			margin-bottom: 56px;
		}

		@media (max-width: $screen-sm) {
			margin-bottom: 48px;
		}
	}

	.smallSectionIndent {
		margin-bottom: 64px;

		@media (max-width: $screen-md-max) {
			margin-bottom: 44px;
		}

		@media (max-width: $screen-sm-max) {
			margin-bottom: 34px;
		}

		p:last-child {
			margin-bottom: 0;
		}
	}

	.bigNumberBlock {
		@media (max-width: $screen-sm) {
			&:nth-child(2n) {
				display: flex;
				flex-direction: column-reverse;
			}

			&:nth-child(3),
			&:nth-child(4) {
				.bigNumber {
					margin-bottom: 70px;
				}
			}

			.bigNumber {
				display: block;
				margin-bottom: 40px;
				text-align: left;
			}

			q,
			p {
				br {
					display: none;
				}
			}
		}
	}

	.bigNumber {
		margin-bottom: 0;
		line-height: 0.55;
		font-size: 288px;
		color: $main-orange;

		@media (max-width: $screen-sm-max) {
			text-align: center;
			margin-bottom: 20px;
		}
	}

	.headerContentBlock {
		position: relative;
		padding: 104px 50px 50px 50px;
		max-width: 1400px;
		margin-left: auto;
		margin-right: auto;
		z-index: 2;

		@media (max-width: $screen-sm) {
			padding: 24px 16px;
		}
	}

	.contentBlock {
		padding: 0 50px;
		max-width: 1400px;
		margin-left: auto;
		margin-right: auto;

		@media (max-width: $screen-sm) {
			padding: 0 16px;
		}
	}

	.howItWorkBlock {
		position: relative;
		padding: 0 16px 42px 16px;
		padding-bottom: 42px;

		@media (max-width: $screen-sm) {
			padding: 0 16px 32px 16px;
		}

		.howItWorkBlockImage {
			position: relative;
			left: -15%;

			@media (max-width: $screen-md) {
				text-align: center;
				left: 0%;
				margin-bottom: 20px;
			}

			img {
				max-width: 100%;

				@media (max-width: $screen-sm) {
					max-width: 300px;
					width: 100%;
				}

				@media (min-width: $screen-xl) {
					max-width: 500px;
				}
			}
		}
	}

	.listOfItemsBlock {
		position: relative;
		margin-bottom: 112px;

		@media (max-width: $screen-sm) {
			margin-bottom: 0;
		}

		.gutterRow {
			margin-left: -32px;
			margin-right: -32px;

			.gutterCol {
				padding: 0 32px;

				@media (max-width: $screen-sm) {
					&:nth-child(2n) {
						:global(.row) {
							display: flex;
							flex-direction: column-reverse;
						}
					}
				}
			}
		}

		ol {
			padding: 0 32px;

			@media (max-width: $screen-sm) {
				list-style: inside decimal;
				padding: 0;
				margin-bottom: 0;
			}

			li {
				font-family: $common-font;
				line-height: 2;
				font-size: 24px;
				color: $strong-blue;

				@media (max-width: $screen-sm) {
					font-size: 16px;
					line-height: 24px;
				}

				a {
					color: inherit;
				}

				@media (max-width: $screen-xs-max) {
					line-height: 1.5;
					margin-bottom: 16px;
				}

				&.active {
					color: $strong-orange;
					cursor: pointer;
					font-weight: bold;

					&:hover {
						text-decoration: none;
					}

					&:after {
						content: '';
						background: url('/img/star-icon.png') 50% 50% no-repeat / cover;
						width: 24px;
						height: 24px;
						display: inline-block;
						margin-left: 5px;
						vertical-align: sub;
					}
				}
			}

			.listImage {
				margin: 45px auto;
				text-align: center;

				// img {
				// 	min-width: 200px;
				// }

				@media (max-width: $screen-sm) {
					img {
						width: 100% !important;
						max-width: 300px;
					}
				}

				.first {
					width: 74%;
				}
				.second {
					width: 72%;
				}
				.third {
					width: 77%;
				}
			}
		}
	}

	.stagesBlock {
		.gutterRow {
			margin-left: -16px;
			margin-right: -16px;

			&.sectionIndent {
				@media (max-width: $screen-sm) {
					margin-bottom: 0;
				}
			}

			@media (max-width: $screen-sm) {
				&:nth-child(2n) {
					display: flex;
					flex-direction: column-reverse;
				}
			}

			.gutterCol {
				padding: 0 16px;
			}
		}

		h4 {
			margin-bottom: 49px;

			@media (max-width: $screen-sm) {
				margin-bottom: 16px;
			}
		}

		b {
			display: inline-block;
			margin-bottom: 16px;
			font-family: $extra-font;
			font-weight: bold;
			line-height: 24px;
			font-size: 24px;
			letter-spacing: 0.08em;
			color: $strong-blue;
		}

		.leftDirection {
			@media (max-width: $screen-xs-max) {
				&:global(.ant-row) {
					display: flex;
					flex-direction: column-reverse;
				}
			}

			.active {
				&:after {
					display: none;
				}
				&:before {
					content: '← ';
				}
			}
		}

		.buttonsBlock {
			display: flex;
			flex-direction: column;
			// border-radius: 8px;
			// border: 2px solid $light-orange;

			@media (max-width: $screen-sm-max) {
				// margin-bottom: 20px;
				display: none;
			}

			.sliderNavItem {
				font-family: $extra-font;
				font-weight: bold;
				line-height: 32px;
				font-size: 24px;
				text-align: center;
				letter-spacing: 0.04em;
				color: $main-orange;
				padding: 16px 0;
				width: 100%;
				cursor: pointer;
				outline: none;

				border-left: 2px solid $light-orange;
				border-right: 2px solid $light-orange;
				border-top: 2px solid $light-orange;

				&:hover {
					background-color: $light-orange;
				}

				&:first-child {
					border-top-right-radius: 6px;
					border-top-left-radius: 6px;
				}
				&:last-child {
					border-bottom-right-radius: 6px;
					border-bottom-left-radius: 6px;
					border-bottom: 2px solid $light-orange !important;
				}

				&.active {
					background: $main-orange;
					color: white;
					border-color: $main-orange;

					& + div {
						border-top-color: transparent;
					}

					&:last-child {
						border-bottom-color: $main-orange !important;
					}
				}
			}
		}

		.slider {
			// margin-top: -27px;

			@media (max-width: $screen-sm) {
				margin-bottom: 32px;
			}

			.sliderItem {
				margin-bottom: 32px;

				img {
					// max-width: ~'calc(100% - 50px)';
					margin: auto;
					box-shadow: 0px 16px 40px rgb(237, 240, 241);
					border-radius: 8px;
				}
				// background: url('/img/main-header.png') 50% 0% no-repeat / contain;
			}
		}
	}

	.faqBlock {
		h4 {
			margin-bottom: 24px;
		}
	}

	.buyBlock {
		text-align: center;
		background-image: url('/img/buyBlock.png');
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: contain;

		@media (max-width: $screen-lg) {
			background-size: 120%;
		}

		@media (max-width: $screen-sm) {
			background-size: cover;
		}

		.textBlock {
			padding-top: 25%;

			h3 {
				font-size: 42px;
				color: white;
				line-height: 72px;
				margin-bottom: 0;
				letter-spacing: -0.01em;

				@media (max-width: $screen-md-max) {
					font-size: 32px;
					margin-bottom: 20px;
				}
			}

			h1 {
				color: white;
				font-size: 253px;
				transform: rotate(-6deg);
				line-height: normal;
				margin-bottom: -25px;
				margin-top: -80px;

				@media (max-width: $screen-md-max) {
					font-size: 160px;
					margin-bottom: 10px;
				}

				@media (max-width: $screen-sm) {
					font-size: 80px;
					margin-top: -40px;
				}
			}

			.tariffsBlock {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				margin-bottom: 15px;

				@media (max-width: $screen-sm) {
					justify-content: space-between;
				}

				@media (max-width: $screen-xs) {
					justify-content: center;
				}

				.tariff {
					max-width: 100%;
					width: 256px;
					padding: 16px;
					margin: 0 16px 16px;
					text-align: center;
					background-color: white;
					border: 3px solid $light-orange;
					box-sizing: border-box;
					border-radius: 8px;
					cursor: pointer;
					outline: none;

					&:hover {
						border-color: $main-orange;
					}

					&.active {
						border-color: $strong-orange;
					}

					&:first-child {
						margin-left: 0;

						@media (max-width: $screen-xs) {
							margin-left: 16px;
						}
					}

					&:last-child {
						margin-right: 0;

						@media (max-width: $screen-xs) {
							margin-right: 16px;
						}
					}

					.caption {
						font-family: $extra-font;
						line-height: 20px;
						letter-spacing: 0.04em;
						color: $strong-blue;
					}

					.price {
						font-family: $extra-font;
						font-weight: bold;
						font-size: 40px;
						line-height: 48px;
						letter-spacing: 0.08em;
						color: $strong-blue;
					}

					.desc {
						font-family: $extra-font;
						line-height: 20px;
						letter-spacing: 0.04em;
						color: $strong-gray;
					}
				}
			}
		}

		.buyButton {
			padding: 0 19%;

			@media (max-width: $screen-md) {
				padding: 0;
			}
		}

		.policyBlock {
			padding-bottom: 21%;

			p {
				margin-top: 8%;
				color: $main-green;
				font-style: italic;
				line-height: 28px;
				font-size: 24px;
				margin-bottom: 0;

				@media (max-width: $screen-md-max) {
					margin-top: 4%;
				}

				@media (max-width: $screen-sm) {
					br {
						display: none;
					}
				}
			}

			a {
				font-weight: bold;
				line-height: 28px;
				font-size: 24px;
				color: $main-orange;
				text-decoration: underline;

				&:hover {
					text-decoration: none;
				}
			}
		}
	}

	.crewBlock {
		text-align: center;
		margin-bottom: 96px;

		@media (max-width: $screen-xs) {
			margin-bottom: 48px;
		}

		.gutterRow {
			margin-left: -16px;
			margin-right: -16px;

			@media (max-width: $screen-xs) {
				margin-bottom: 10px;

				p,
				i {
					text-align: center;
				}
			}

			.gutterCol {
				padding: 0 16px;
			}
		}

		h5 {
			color: $strong-blue;
			margin-bottom: 36px;
		}

		i {
			display: block;
			line-height: 36px;
			font-size: 24px;
			text-align: right;
			color: $strong-blue;
			margin-bottom: 16px;

			@media (max-width: $screen-sm) {
				font-size: 16px;
				line-height: 20px;
			}
		}

		p {
			margin-bottom: 16px;
			text-align: left;

			@media (max-width: $screen-sm) {
				line-height: 20px;
			}
		}
	}
}

.headerImage {
	position: absolute;
	right: -4%;
	top: -0.4%;
	width: 41%;
	z-index: 1;

	img {
		max-width: 100%;

		@media (min-width: $screen-xl) {
			max-width: 700px;
		}

		@media (max-width: 400px) {
			max-width: 70%;
		}
	}

	@media (max-width: $screen-sm-max) {
		width: 44%;
	}

	@media (max-width: $screen-xs) {
		position: relative;
		margin: auto;
		left: 0;
		right: 0;
		top: 0px;
		margin-top: 20px;
		width: calc(100% - 32px);
		max-width: 300px;
		text-align: center;
	}
}

.listImage {
	width: 83%;
	img {
		max-width: 100%;
	}
}

.slider {
	img {
		max-width: 100%;
	}

	:global {
		.slick-dots {
			display: flex !important;
			position: relative;
			top: 0;
			justify-content: center;
			text-align: center;
			padding: 0;
			margin-bottom: 0;
			height: 8px;

			li {
				display: inline-block;
				margin: 0 4px;
				list-style: none;
				width: auto;
				height: auto;

				&.slick-active {
					button {
						&:before {
							background-color: $main-orange;
						}
					}
				}

				button {
					width: auto;
					height: auto;

					&:before {
						width: 8px;
						height: 8px;
						background-color: $main-gray;
						border-radius: 50%;
						border: none;
						padding: 0;
						color: transparent;
						overflow: hidden;
						cursor: pointer;

						&:focus {
							outline: none;
						}
					}
				}
			}
		}
	}
}
