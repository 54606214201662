@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css');

@import 'variables';
@import 'core';
@import 'typography';

body {
	font-size: 16px;
	color: $main-black;
	font-family: $font-family;
	margin: 0;
	line-height: 1.5;
}
