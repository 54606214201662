@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css);
@import url(https://fonts.googleapis.com/css?family=Alegreya+Sans:400,900&display=swap&subset=cyrillic);
@import url(https://fonts.googleapis.com/css?family=Alegreya:400,400i&display=swap&subset=cyrillic);
.Buy_buyComponent__1uWiV input {
  width: 100%;
  font-family: "Alegreya Sans SC", sans-serif;
  font-weight: bold;
  font-size: 24px;
  letter-spacing: 0.05em;
  border: 3px solid #ff8f2c;
  margin-right: 28px;
  border-radius: 8px;
  border-right-width: 3px; }
  .Buy_buyComponent__1uWiV input:focus {
    border-right-width: 3px;
    outline: none; }
  .Buy_buyComponent__1uWiV input:hover {
    border-color: #ff5500; }
  .Buy_buyComponent__1uWiV input[type='email'] {
    height: 64px;
    color: #ff8f2c;
    min-width: 250px;
    padding: 0 24px;
    margin-right: 28px; }
    @media (max-width: 1199px) {
      .Buy_buyComponent__1uWiV input[type='email'] {
        margin-bottom: 10px;
        text-align: center; } }
    @media (max-width: 768px) {
      .Buy_buyComponent__1uWiV input[type='email'] {
        margin-right: 0;
        height: 56px;
        font-size: 20px; } }
    .Buy_buyComponent__1uWiV input[type='email']:focus {
      outline: none; }
    .Buy_buyComponent__1uWiV input[type='email']::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #ffe0c4; }
    .Buy_buyComponent__1uWiV input[type='email']::-moz-placeholder {
      /* Firefox 19+ */
      color: #ffe0c4; }
    .Buy_buyComponent__1uWiV input[type='email']:-ms-input-placeholder {
      /* IE 10+ */
      color: #ffe0c4; }
    .Buy_buyComponent__1uWiV input[type='email']:-moz-placeholder {
      /* Firefox 18- */
      color: #ffe0c4; }

.Buy_buyComponent__1uWiV .input-wrapper {
  display: flex; }
  @media (max-width: 768px) {
    .Buy_buyComponent__1uWiV .input-wrapper {
      flex-wrap: wrap; } }

.Buy_buyComponent__1uWiV .group-addon {
  display: flex;
  white-space: nowrap;
  align-items: center;
  width: auto;
  background: linear-gradient(180deg, #ffa555 0%, #ff8f2c 100%);
  border: none;
  cursor: pointer; }
  @media (max-width: 1199px) {
    .Buy_buyComponent__1uWiV .group-addon {
      flex-wrap: wrap; } }
  @media (max-width: 1199px) {
    .Buy_buyComponent__1uWiV .group-addon {
      width: 100%;
      justify-content: center;
      height: 64px; } }
  @media (max-width: 768px) {
    .Buy_buyComponent__1uWiV .group-addon {
      height: 56px; } }
  .Buy_buyComponent__1uWiV .group-addon:hover {
    background: linear-gradient(180deg, #ff8f2c 0%, #ff5500 100%); }
  .Buy_buyComponent__1uWiV .group-addon:last-child {
    border-radius: 8px; }
  .Buy_buyComponent__1uWiV .group-addon button {
    padding: 0 25px;
    font-family: "Alegreya Sans SC", sans-serif;
    font-weight: bold;
    font-size: 24px;
    letter-spacing: 0.04em;
    background: none;
    border: none;
    line-height: 1;
    width: 100%;
    height: 100%;
    outline: none;
    color: white;
    cursor: pointer; }
    @media (max-width: 768px) {
      .Buy_buyComponent__1uWiV .group-addon button {
        font-size: 20px; } }
    .Buy_buyComponent__1uWiV .group-addon button span {
      text-shadow: 0px 1px 1px #ff7800;
      color: white; }

.Loading_loader__3pmY_,
.Loading_loader__3pmY_:before,
.Loading_loader__3pmY_:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: Loading_load7__3Qimk 1.8s infinite ease-in-out;
  animation: Loading_load7__3Qimk 1.8s infinite ease-in-out; }

.Loading_loader__3pmY_ {
  color: #ff8f2c;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s; }

.Loading_loader__3pmY_:before,
.Loading_loader__3pmY_:after {
  content: '';
  position: absolute;
  top: 0; }

.Loading_loader__3pmY_:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s; }

.Loading_loader__3pmY_:after {
  left: 3.5em; }

@-webkit-keyframes Loading_load7__3Qimk {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em; }
  40% {
    box-shadow: 0 2.5em 0 0; } }

@keyframes Loading_load7__3Qimk {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em; }
  40% {
    box-shadow: 0 2.5em 0 0; } }

@charset "UTF-8";
.WorkLikePro_workLikeProLanding__X-zs8 {
  position: relative;
  overflow-x: hidden;
  min-height: 100vh; }
  .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_error__Ve9h- {
    color: #ff5500; }
  .WorkLikePro_workLikeProLanding__X-zs8 .container-fluid {
    padding: 0; }
  .WorkLikePro_workLikeProLanding__X-zs8 h2,
  .WorkLikePro_workLikeProLanding__X-zs8 h4,
  .WorkLikePro_workLikeProLanding__X-zs8 p,
  .WorkLikePro_workLikeProLanding__X-zs8 q {
    color: #08054c; }
    .WorkLikePro_workLikeProLanding__X-zs8 h2 span,
    .WorkLikePro_workLikeProLanding__X-zs8 h4 span,
    .WorkLikePro_workLikeProLanding__X-zs8 p span,
    .WorkLikePro_workLikeProLanding__X-zs8 q span {
      color: inherit; }
  .WorkLikePro_workLikeProLanding__X-zs8 h2 {
    line-height: 60px;
    letter-spacing: -0.01em; }
    @media (max-width: 768px) {
      .WorkLikePro_workLikeProLanding__X-zs8 h2 {
        font-size: 46px;
        line-height: 46px; } }
    @media (max-width: 768px) {
      .WorkLikePro_workLikeProLanding__X-zs8 h2 {
        font-size: 46px;
        line-height: 46px; } }
    @media (max-width: 400px) {
      .WorkLikePro_workLikeProLanding__X-zs8 h2 {
        font-size: 37px;
        line-height: 37px; } }
  .WorkLikePro_workLikeProLanding__X-zs8 h4 {
    font-size: 40px;
    letter-spacing: 0.08em;
    font-family: "Alegreya Sans SC", sans-serif;
    margin-bottom: 8px; }
    @media (max-width: 768px) {
      .WorkLikePro_workLikeProLanding__X-zs8 h4 {
        font-size: 32px;
        line-height: 40px; } }
  .WorkLikePro_workLikeProLanding__X-zs8 h5 {
    line-height: 24px;
    font-size: 24px;
    color: #4552af;
    font-weight: bold;
    letter-spacing: 0.08em; }
    @media (max-width: 768px) {
      .WorkLikePro_workLikeProLanding__X-zs8 h5 {
        line-height: 20px;
        font-size: 20px; } }
  .WorkLikePro_workLikeProLanding__X-zs8 q {
    display: inline-block;
    margin-bottom: 24px;
    line-height: 52px;
    font-size: 48px;
    quotes: none; }
    @media (max-width: 768px) {
      .WorkLikePro_workLikeProLanding__X-zs8 q {
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 16px; } }
  .WorkLikePro_workLikeProLanding__X-zs8 p {
    font-size: 24px;
    line-height: 36px; }
    @media (max-width: 768px) {
      .WorkLikePro_workLikeProLanding__X-zs8 p {
        font-size: 16px;
        line-height: 24px; } }
  .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_mainHeaderTitle__-dmUi {
    margin-bottom: 16px; }
  .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_sectionIndent__2FgS8 {
    margin-bottom: 96px; }
    @media (max-width: 992px) {
      .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_sectionIndent__2FgS8 {
        margin-bottom: 56px; } }
    @media (max-width: 768px) {
      .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_sectionIndent__2FgS8 {
        margin-bottom: 48px; } }
  .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_smallSectionIndent__3GF6B {
    margin-bottom: 64px; }
    @media (max-width: 1199px) {
      .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_smallSectionIndent__3GF6B {
        margin-bottom: 44px; } }
    @media (max-width: 991px) {
      .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_smallSectionIndent__3GF6B {
        margin-bottom: 34px; } }
    .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_smallSectionIndent__3GF6B p:last-child {
      margin-bottom: 0; }
  @media (max-width: 768px) {
    .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_bigNumberBlock__1rxTW:nth-child(2n) {
      display: flex;
      flex-direction: column-reverse; }
    .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_bigNumberBlock__1rxTW:nth-child(3) .WorkLikePro_bigNumber__3iIHE, .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_bigNumberBlock__1rxTW:nth-child(4) .WorkLikePro_bigNumber__3iIHE {
      margin-bottom: 70px; }
    .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_bigNumberBlock__1rxTW .WorkLikePro_bigNumber__3iIHE {
      display: block;
      margin-bottom: 40px;
      text-align: left; }
    .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_bigNumberBlock__1rxTW q br,
    .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_bigNumberBlock__1rxTW p br {
      display: none; } }
  .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_bigNumber__3iIHE {
    margin-bottom: 0;
    line-height: 0.55;
    font-size: 288px;
    color: #ff8f2c; }
    @media (max-width: 991px) {
      .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_bigNumber__3iIHE {
        text-align: center;
        margin-bottom: 20px; } }
  .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_headerContentBlock__29M5W {
    position: relative;
    padding: 104px 50px 50px 50px;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    z-index: 2; }
    @media (max-width: 768px) {
      .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_headerContentBlock__29M5W {
        padding: 24px 16px; } }
  .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_contentBlock__1ICva {
    padding: 0 50px;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto; }
    @media (max-width: 768px) {
      .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_contentBlock__1ICva {
        padding: 0 16px; } }
  .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_howItWorkBlock__1iVIq {
    position: relative;
    padding: 0 16px 42px 16px;
    padding-bottom: 42px; }
    @media (max-width: 768px) {
      .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_howItWorkBlock__1iVIq {
        padding: 0 16px 32px 16px; } }
    .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_howItWorkBlock__1iVIq .WorkLikePro_howItWorkBlockImage__1x7Zy {
      position: relative;
      left: -15%; }
      @media (max-width: 992px) {
        .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_howItWorkBlock__1iVIq .WorkLikePro_howItWorkBlockImage__1x7Zy {
          text-align: center;
          left: 0%;
          margin-bottom: 20px; } }
      .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_howItWorkBlock__1iVIq .WorkLikePro_howItWorkBlockImage__1x7Zy img {
        max-width: 100%; }
        @media (max-width: 768px) {
          .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_howItWorkBlock__1iVIq .WorkLikePro_howItWorkBlockImage__1x7Zy img {
            max-width: 300px;
            width: 100%; } }
        @media (min-width: 1500px) {
          .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_howItWorkBlock__1iVIq .WorkLikePro_howItWorkBlockImage__1x7Zy img {
            max-width: 500px; } }
  .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_listOfItemsBlock__2I1_A {
    position: relative;
    margin-bottom: 112px; }
    @media (max-width: 768px) {
      .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_listOfItemsBlock__2I1_A {
        margin-bottom: 0; } }
    .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_listOfItemsBlock__2I1_A .WorkLikePro_gutterRow__5pWTU {
      margin-left: -32px;
      margin-right: -32px; }
      .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_listOfItemsBlock__2I1_A .WorkLikePro_gutterRow__5pWTU .WorkLikePro_gutterCol__2BcN0 {
        padding: 0 32px; }
        @media (max-width: 768px) {
          .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_listOfItemsBlock__2I1_A .WorkLikePro_gutterRow__5pWTU .WorkLikePro_gutterCol__2BcN0:nth-child(2n) .row {
            display: flex;
            flex-direction: column-reverse; } }
    .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_listOfItemsBlock__2I1_A ol {
      padding: 0 32px; }
      @media (max-width: 768px) {
        .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_listOfItemsBlock__2I1_A ol {
          list-style: inside decimal;
          padding: 0;
          margin-bottom: 0; } }
      .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_listOfItemsBlock__2I1_A ol li {
        font-family: "Alegreya", sans-serif;
        line-height: 2;
        font-size: 24px;
        color: #08054c; }
        @media (max-width: 768px) {
          .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_listOfItemsBlock__2I1_A ol li {
            font-size: 16px;
            line-height: 24px; } }
        .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_listOfItemsBlock__2I1_A ol li a {
          color: inherit; }
        @media (max-width: 767px) {
          .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_listOfItemsBlock__2I1_A ol li {
            line-height: 1.5;
            margin-bottom: 16px; } }
        .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_listOfItemsBlock__2I1_A ol li.WorkLikePro_active__1H505 {
          color: #ff5500;
          cursor: pointer;
          font-weight: bold; }
          .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_listOfItemsBlock__2I1_A ol li.WorkLikePro_active__1H505:hover {
            text-decoration: none; }
          .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_listOfItemsBlock__2I1_A ol li.WorkLikePro_active__1H505:after {
            content: '';
            background: url("/img/star-icon.png") 50% 50% no-repeat/cover;
            width: 24px;
            height: 24px;
            display: inline-block;
            margin-left: 5px;
            vertical-align: sub; }
      .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_listOfItemsBlock__2I1_A ol .WorkLikePro_listImage__p5ync {
        margin: 45px auto;
        text-align: center; }
        @media (max-width: 768px) {
          .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_listOfItemsBlock__2I1_A ol .WorkLikePro_listImage__p5ync img {
            width: 100% !important;
            max-width: 300px; } }
        .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_listOfItemsBlock__2I1_A ol .WorkLikePro_listImage__p5ync .WorkLikePro_first__UtFxJ {
          width: 74%; }
        .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_listOfItemsBlock__2I1_A ol .WorkLikePro_listImage__p5ync .WorkLikePro_second__2-yg_ {
          width: 72%; }
        .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_listOfItemsBlock__2I1_A ol .WorkLikePro_listImage__p5ync .WorkLikePro_third__2JtdV {
          width: 77%; }
  .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_stagesBlock__OATzb .WorkLikePro_gutterRow__5pWTU {
    margin-left: -16px;
    margin-right: -16px; }
    @media (max-width: 768px) {
      .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_stagesBlock__OATzb .WorkLikePro_gutterRow__5pWTU.WorkLikePro_sectionIndent__2FgS8 {
        margin-bottom: 0; } }
    @media (max-width: 768px) {
      .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_stagesBlock__OATzb .WorkLikePro_gutterRow__5pWTU:nth-child(2n) {
        display: flex;
        flex-direction: column-reverse; } }
    .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_stagesBlock__OATzb .WorkLikePro_gutterRow__5pWTU .WorkLikePro_gutterCol__2BcN0 {
      padding: 0 16px; }
  .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_stagesBlock__OATzb h4 {
    margin-bottom: 49px; }
    @media (max-width: 768px) {
      .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_stagesBlock__OATzb h4 {
        margin-bottom: 16px; } }
  .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_stagesBlock__OATzb b {
    display: inline-block;
    margin-bottom: 16px;
    font-family: "Alegreya Sans SC", sans-serif;
    font-weight: bold;
    line-height: 24px;
    font-size: 24px;
    letter-spacing: 0.08em;
    color: #08054c; }
  @media (max-width: 767px) {
    .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_stagesBlock__OATzb .WorkLikePro_leftDirection__wJn-D.ant-row {
      display: flex;
      flex-direction: column-reverse; } }
  .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_stagesBlock__OATzb .WorkLikePro_leftDirection__wJn-D .WorkLikePro_active__1H505:after {
    display: none; }
  .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_stagesBlock__OATzb .WorkLikePro_leftDirection__wJn-D .WorkLikePro_active__1H505:before {
    content: '← '; }
  .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_stagesBlock__OATzb .WorkLikePro_buttonsBlock__1GkuL {
    display: flex;
    flex-direction: column; }
    @media (max-width: 991px) {
      .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_stagesBlock__OATzb .WorkLikePro_buttonsBlock__1GkuL {
        display: none; } }
    .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_stagesBlock__OATzb .WorkLikePro_buttonsBlock__1GkuL .WorkLikePro_sliderNavItem__2WF2n {
      font-family: "Alegreya Sans SC", sans-serif;
      font-weight: bold;
      line-height: 32px;
      font-size: 24px;
      text-align: center;
      letter-spacing: 0.04em;
      color: #ff8f2c;
      padding: 16px 0;
      width: 100%;
      cursor: pointer;
      outline: none;
      border-left: 2px solid #ffe0c4;
      border-right: 2px solid #ffe0c4;
      border-top: 2px solid #ffe0c4; }
      .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_stagesBlock__OATzb .WorkLikePro_buttonsBlock__1GkuL .WorkLikePro_sliderNavItem__2WF2n:hover {
        background-color: #ffe0c4; }
      .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_stagesBlock__OATzb .WorkLikePro_buttonsBlock__1GkuL .WorkLikePro_sliderNavItem__2WF2n:first-child {
        border-top-right-radius: 6px;
        border-top-left-radius: 6px; }
      .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_stagesBlock__OATzb .WorkLikePro_buttonsBlock__1GkuL .WorkLikePro_sliderNavItem__2WF2n:last-child {
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
        border-bottom: 2px solid #ffe0c4 !important; }
      .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_stagesBlock__OATzb .WorkLikePro_buttonsBlock__1GkuL .WorkLikePro_sliderNavItem__2WF2n.WorkLikePro_active__1H505 {
        background: #ff8f2c;
        color: white;
        border-color: #ff8f2c; }
        .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_stagesBlock__OATzb .WorkLikePro_buttonsBlock__1GkuL .WorkLikePro_sliderNavItem__2WF2n.WorkLikePro_active__1H505 + div {
          border-top-color: transparent; }
        .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_stagesBlock__OATzb .WorkLikePro_buttonsBlock__1GkuL .WorkLikePro_sliderNavItem__2WF2n.WorkLikePro_active__1H505:last-child {
          border-bottom-color: #ff8f2c !important; }
  @media (max-width: 768px) {
    .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_stagesBlock__OATzb .WorkLikePro_slider__2Ckjf {
      margin-bottom: 32px; } }
  .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_stagesBlock__OATzb .WorkLikePro_slider__2Ckjf .WorkLikePro_sliderItem__2sqcD {
    margin-bottom: 32px; }
    .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_stagesBlock__OATzb .WorkLikePro_slider__2Ckjf .WorkLikePro_sliderItem__2sqcD img {
      margin: auto;
      box-shadow: 0px 16px 40px #edf0f1;
      border-radius: 8px; }
  .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_faqBlock__V_VSG h4 {
    margin-bottom: 24px; }
  .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_buyBlock__aVxM3 {
    text-align: center;
    background-image: url("/img/buyBlock.png");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain; }
    @media (max-width: 1200px) {
      .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_buyBlock__aVxM3 {
        background-size: 120%; } }
    @media (max-width: 768px) {
      .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_buyBlock__aVxM3 {
        background-size: cover; } }
    .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_buyBlock__aVxM3 .WorkLikePro_textBlock__3Wyxg {
      padding-top: 25%; }
      .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_buyBlock__aVxM3 .WorkLikePro_textBlock__3Wyxg h3 {
        font-size: 42px;
        color: white;
        line-height: 72px;
        margin-bottom: 0;
        letter-spacing: -0.01em; }
        @media (max-width: 1199px) {
          .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_buyBlock__aVxM3 .WorkLikePro_textBlock__3Wyxg h3 {
            font-size: 32px;
            margin-bottom: 20px; } }
      .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_buyBlock__aVxM3 .WorkLikePro_textBlock__3Wyxg h1 {
        color: white;
        font-size: 253px;
        transform: rotate(-6deg);
        line-height: normal;
        margin-bottom: -25px;
        margin-top: -80px; }
        @media (max-width: 1199px) {
          .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_buyBlock__aVxM3 .WorkLikePro_textBlock__3Wyxg h1 {
            font-size: 160px;
            margin-bottom: 10px; } }
        @media (max-width: 768px) {
          .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_buyBlock__aVxM3 .WorkLikePro_textBlock__3Wyxg h1 {
            font-size: 80px;
            margin-top: -40px; } }
      .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_buyBlock__aVxM3 .WorkLikePro_textBlock__3Wyxg .WorkLikePro_tariffsBlock__149X- {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 15px; }
        @media (max-width: 768px) {
          .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_buyBlock__aVxM3 .WorkLikePro_textBlock__3Wyxg .WorkLikePro_tariffsBlock__149X- {
            justify-content: space-between; } }
        @media (max-width: 576px) {
          .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_buyBlock__aVxM3 .WorkLikePro_textBlock__3Wyxg .WorkLikePro_tariffsBlock__149X- {
            justify-content: center; } }
        .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_buyBlock__aVxM3 .WorkLikePro_textBlock__3Wyxg .WorkLikePro_tariffsBlock__149X- .WorkLikePro_tariff__iQb3h {
          max-width: 100%;
          width: 256px;
          padding: 16px;
          margin: 0 16px 16px;
          text-align: center;
          background-color: white;
          border: 3px solid #ffe0c4;
          box-sizing: border-box;
          border-radius: 8px;
          cursor: pointer;
          outline: none; }
          .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_buyBlock__aVxM3 .WorkLikePro_textBlock__3Wyxg .WorkLikePro_tariffsBlock__149X- .WorkLikePro_tariff__iQb3h:hover {
            border-color: #ff8f2c; }
          .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_buyBlock__aVxM3 .WorkLikePro_textBlock__3Wyxg .WorkLikePro_tariffsBlock__149X- .WorkLikePro_tariff__iQb3h.WorkLikePro_active__1H505 {
            border-color: #ff5500; }
          .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_buyBlock__aVxM3 .WorkLikePro_textBlock__3Wyxg .WorkLikePro_tariffsBlock__149X- .WorkLikePro_tariff__iQb3h:first-child {
            margin-left: 0; }
            @media (max-width: 576px) {
              .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_buyBlock__aVxM3 .WorkLikePro_textBlock__3Wyxg .WorkLikePro_tariffsBlock__149X- .WorkLikePro_tariff__iQb3h:first-child {
                margin-left: 16px; } }
          .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_buyBlock__aVxM3 .WorkLikePro_textBlock__3Wyxg .WorkLikePro_tariffsBlock__149X- .WorkLikePro_tariff__iQb3h:last-child {
            margin-right: 0; }
            @media (max-width: 576px) {
              .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_buyBlock__aVxM3 .WorkLikePro_textBlock__3Wyxg .WorkLikePro_tariffsBlock__149X- .WorkLikePro_tariff__iQb3h:last-child {
                margin-right: 16px; } }
          .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_buyBlock__aVxM3 .WorkLikePro_textBlock__3Wyxg .WorkLikePro_tariffsBlock__149X- .WorkLikePro_tariff__iQb3h .WorkLikePro_caption__bqf0- {
            font-family: "Alegreya Sans SC", sans-serif;
            line-height: 20px;
            letter-spacing: 0.04em;
            color: #08054c; }
          .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_buyBlock__aVxM3 .WorkLikePro_textBlock__3Wyxg .WorkLikePro_tariffsBlock__149X- .WorkLikePro_tariff__iQb3h .WorkLikePro_price__3afrp {
            font-family: "Alegreya Sans SC", sans-serif;
            font-weight: bold;
            font-size: 40px;
            line-height: 48px;
            letter-spacing: 0.08em;
            color: #08054c; }
          .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_buyBlock__aVxM3 .WorkLikePro_textBlock__3Wyxg .WorkLikePro_tariffsBlock__149X- .WorkLikePro_tariff__iQb3h .WorkLikePro_desc__1r2MZ {
            font-family: "Alegreya Sans SC", sans-serif;
            line-height: 20px;
            letter-spacing: 0.04em;
            color: #95a0b0; }
    .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_buyBlock__aVxM3 .WorkLikePro_buyButton__27Ugp {
      padding: 0 19%; }
      @media (max-width: 992px) {
        .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_buyBlock__aVxM3 .WorkLikePro_buyButton__27Ugp {
          padding: 0; } }
    .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_buyBlock__aVxM3 .WorkLikePro_policyBlock__20LA7 {
      padding-bottom: 21%; }
      .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_buyBlock__aVxM3 .WorkLikePro_policyBlock__20LA7 p {
        margin-top: 8%;
        color: #54e5ce;
        font-style: italic;
        line-height: 28px;
        font-size: 24px;
        margin-bottom: 0; }
        @media (max-width: 1199px) {
          .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_buyBlock__aVxM3 .WorkLikePro_policyBlock__20LA7 p {
            margin-top: 4%; } }
        @media (max-width: 768px) {
          .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_buyBlock__aVxM3 .WorkLikePro_policyBlock__20LA7 p br {
            display: none; } }
      .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_buyBlock__aVxM3 .WorkLikePro_policyBlock__20LA7 a {
        font-weight: bold;
        line-height: 28px;
        font-size: 24px;
        color: #ff8f2c;
        text-decoration: underline; }
        .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_buyBlock__aVxM3 .WorkLikePro_policyBlock__20LA7 a:hover {
          text-decoration: none; }
  .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_crewBlock__97XuL {
    text-align: center;
    margin-bottom: 96px; }
    @media (max-width: 576px) {
      .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_crewBlock__97XuL {
        margin-bottom: 48px; } }
    .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_crewBlock__97XuL .WorkLikePro_gutterRow__5pWTU {
      margin-left: -16px;
      margin-right: -16px; }
      @media (max-width: 576px) {
        .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_crewBlock__97XuL .WorkLikePro_gutterRow__5pWTU {
          margin-bottom: 10px; }
          .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_crewBlock__97XuL .WorkLikePro_gutterRow__5pWTU p,
          .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_crewBlock__97XuL .WorkLikePro_gutterRow__5pWTU i {
            text-align: center; } }
      .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_crewBlock__97XuL .WorkLikePro_gutterRow__5pWTU .WorkLikePro_gutterCol__2BcN0 {
        padding: 0 16px; }
    .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_crewBlock__97XuL h5 {
      color: #08054c;
      margin-bottom: 36px; }
    .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_crewBlock__97XuL i {
      display: block;
      line-height: 36px;
      font-size: 24px;
      text-align: right;
      color: #08054c;
      margin-bottom: 16px; }
      @media (max-width: 768px) {
        .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_crewBlock__97XuL i {
          font-size: 16px;
          line-height: 20px; } }
    .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_crewBlock__97XuL p {
      margin-bottom: 16px;
      text-align: left; }
      @media (max-width: 768px) {
        .WorkLikePro_workLikeProLanding__X-zs8 .WorkLikePro_crewBlock__97XuL p {
          line-height: 20px; } }

.WorkLikePro_headerImage__209Mw {
  position: absolute;
  right: -4%;
  top: -0.4%;
  width: 41%;
  z-index: 1; }
  .WorkLikePro_headerImage__209Mw img {
    max-width: 100%; }
    @media (min-width: 1500px) {
      .WorkLikePro_headerImage__209Mw img {
        max-width: 700px; } }
    @media (max-width: 400px) {
      .WorkLikePro_headerImage__209Mw img {
        max-width: 70%; } }
  @media (max-width: 991px) {
    .WorkLikePro_headerImage__209Mw {
      width: 44%; } }
  @media (max-width: 576px) {
    .WorkLikePro_headerImage__209Mw {
      position: relative;
      margin: auto;
      left: 0;
      right: 0;
      top: 0px;
      margin-top: 20px;
      width: calc(100% - 32px);
      max-width: 300px;
      text-align: center; } }

.WorkLikePro_listImage__p5ync {
  width: 83%; }
  .WorkLikePro_listImage__p5ync img {
    max-width: 100%; }

.WorkLikePro_slider__2Ckjf img {
  max-width: 100%; }

.WorkLikePro_slider__2Ckjf .slick-dots {
  display: flex !important;
  position: relative;
  top: 0;
  justify-content: center;
  text-align: center;
  padding: 0;
  margin-bottom: 0;
  height: 8px; }
  .WorkLikePro_slider__2Ckjf .slick-dots li {
    display: inline-block;
    margin: 0 4px;
    list-style: none;
    width: auto;
    height: auto; }
    .WorkLikePro_slider__2Ckjf .slick-dots li.slick-active button:before {
      background-color: #ff8f2c; }
    .WorkLikePro_slider__2Ckjf .slick-dots li button {
      width: auto;
      height: auto; }
      .WorkLikePro_slider__2Ckjf .slick-dots li button:before {
        width: 8px;
        height: 8px;
        background-color: #cfd8e7;
        border-radius: 50%;
        border: none;
        padding: 0;
        color: transparent;
        overflow: hidden;
        cursor: pointer; }
        .WorkLikePro_slider__2Ckjf .slick-dots li button:before:focus {
          outline: none; }

/* stylelint-disable at-rule-no-unknown */
input::-ms-clear,
input::-ms-reveal {
  display: none; }

*,
*::before,
*::after {
  box-sizing: border-box; }

@-ms-viewport {
  width: device-width; }

article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block; }

[tabindex='-1']:focus {
  outline: none !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  font-weight: 500; }

p {
  margin-top: 0;
  margin-bottom: 1em; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  border-bottom: 0;
  cursor: help; }

address {
  margin-bottom: 1em;
  font-style: normal;
  line-height: inherit; }

input[type='text'],
input[type='password'],
input[type='number'],
textarea {
  -webkit-appearance: none; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1em; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 500; }

dd {
  margin-bottom: 0.5em;
  margin-left: 0; }

blockquote {
  margin: 0 0 1em; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

a {
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects; }
  a:active, a:hover {
    text-decoration: none;
    outline: 0; }
  a[disabled] {
    cursor: not-allowed;
    pointer-events: none; }

pre,
code,
kbd,
samp {
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1em;
  overflow: auto; }

figure {
  margin: 0 0 1em; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

a,
area,
button,
[role='button'],
input:not([type='range']),
label,
select,
summary,
textarea {
  touch-action: manipulation; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75em;
  padding-bottom: 0.3em;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type='radio'],
input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0; }

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  margin-bottom: 0.5em;
  padding: 0;
  color: inherit;
  font-size: 1.5em;
  line-height: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type='number']::-webkit-inner-adjust-hue-button,
[type='number']::-webkit-outer-adjust-hue-button {
  height: auto; }

[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item; }

template {
  display: none; }

[hidden] {
  display: none !important; }

mark {
  padding: 0.2em; }

@font-face {
  font-family: 'Alegreya Sans SC';
  src: url("/fonts/AlegreyaSansSC-Regular.eot");
  src: local("Alegreya Sans SC"), url("/fonts/AlegreyaSansSC-Regular.eot?#iefix") format("embedded-opentype"), url("/fonts/AlegreyaSansSC-Regular.woff2") format("woff2"), url("/fonts/AlegreyaSansSC-Regular.woff") format("woff"), url("/fonts/AlegreyaSansSC-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Alegreya Sans SC';
  src: url("/fonts/AlegreyaSansSC-Bold.eot");
  src: local("Alegreya Sans SC"), url("/fonts/AlegreyaSansSC-Bold.eot?#iefix") format("embedded-opentype"), url("/fonts/AlegreyaSansSC-Bold.woff2") format("woff2"), url("/fonts/AlegreyaSansSC-Bold.woff") format("woff");
  font-weight: bold;
  font-display: swap; }

.small {
  font-size: 12px;
  line-height: 16px; }

.italic {
  font-style: italic; }

.blue {
  color: #0074ce; }

h1,
h2,
h3,
h4 {
  font-family: "Alegreya Sans", sans-serif;
  font-style: normal;
  font-weight: bold; }

h1 {
  font-size: 104px; }

h2 {
  font-size: 72px;
  line-height: 72px; }
  h2.extra {
    font-weight: 900;
    letter-spacing: -0.01em; }

h3 {
  font-size: 56px; }

h4 {
  font-size: 32px; }

h5 {
  font-weight: 500;
  line-height: 32px;
  font-size: 32px;
  letter-spacing: 0.12em;
  color: #0074ce;
  font-family: "Alegreya Sans SC", sans-serif; }

a {
  color: #0074ce; }
  a:hover {
    color: #08054c; }

p {
  line-height: 24px; }
  p.extra {
    font-size: 24px;
    line-height: 36px; }
    @media (max-width: 768px) {
      p.extra {
        font-size: 16px;
        line-height: 24px; } }

span {
  color: #cfd8e7; }

q {
  font-family: "Alegreya", sans-serif;
  font-style: italic; }

body {
  font-size: 16px;
  color: #091a32;
  font-family: "Alegreya", sans-serif;
  margin: 0;
  line-height: 1.5; }

