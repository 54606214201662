// COLOR
$main-black: #091a32;
$main-white: white;

$strong-gray: #95a0b0;
$main-gray: #cfd8e7;
$light-gray: white;

$strong-blue: #08054c;
$main-blue: #0074ce;
$light-blue: #95c1e4;

$strong-red: #c50018;
$main-red: #eb1730;
$light-red: #ff9eaa;

$strong-yellow: #f1a614;
$main-yellow: #f1cd14;
$light-yellow: #ffe0c4;

$strong-green: #40c212;
$main-green: #72e716;
$light-green: #c1f796;

// FONT FAMILY
$common-font: 'Alegreya', sans-serif;
$header-font: 'Alegreya Sans', sans-serif;
$extra-font: 'Alegreya Sans SC', sans-serif;
$font-family: $common-font;

// RESPONSIVE
$screen-xs: 576px;
$screen-xs-min: $screen-xs;
$screen-sm: 768px;
$screen-sm-min: $screen-sm;
$screen-md: 992px;
$screen-md-min: $screen-md;
$screen-lg: 1200px;
$screen-lg-min: $screen-lg;
$screen-xl: 1500px;
$screen-xl-min: $screen-xl;
$screen-xs-max: ($screen-sm-min - 1px);
$screen-sm-max: ($screen-md-min - 1px);
$screen-md-max: ($screen-lg-min - 1px);
$screen-lg-max: ($screen-xl-min - 1px);
